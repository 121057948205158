import { graphql } from "gatsby";
import { Seo } from "gatsby-plugin-wpgraphql-seo";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  PostTypeInProject,
  Template,
} from "../../../../config/wordPressConfig";
import { BlogPost } from "../../../interfaces/Blog";
import { usePageForTemplate } from "../../../queries/wpPage/usePageForTemplate";
import { mapSingleWpPostToPost } from "../../../queries/wpPost/mapSingleWpPostToPost";
import { useAllPosts } from "../../../queries/wpPost/useAllPosts";
import RVORegisterTodayCtaSection from "../../03-organisms/RVORegisterTodayCtaSection/RVORegisterTodayCtaSection";
import RVOBlogTemplate from "../../04-templates/RVOBlogTemplate/RVOBlogTemplate";

export const BlogPage = (): JSX.Element => {
  const blogs = usePageForTemplate(Template.KnowledgeDatabase);
  const { t } = useTranslation();

  const posts = useAllPosts();

  const blogPosts: BlogPost[] = useMemo(
    () =>
      posts.map((currentPostObject: GatsbyTypes.WpPostFieldsFragment) => {
        return mapSingleWpPostToPost(currentPostObject);
      }),
    [posts]
  );

  return (
    <>
      <Seo post={blogs} />

      <RVOBlogTemplate
        blogPosts={blogPosts}
        title={t("wissen.header.blog.title")}
        text={t("wissen.header.blog.description")}
        pageTitle="Blog"
        pageId={blogs?.id as string}
        postType={PostTypeInProject.WpPost}
      />

      <RVORegisterTodayCtaSection />
    </>
  );
};

export default BlogPage;

export const query = graphql`
  query BlogPage {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
